<template>
<!-- 要素落实审核tab页 -->
  <div class="implementationReview">
    <base-tabs
      type="border-card"
      v-model="activeNames"
      class="tabs"
      @click="hander"
    >
      <base-tab-pane label="待审核" name="1" />
      <base-tab-pane label="审核通过" name="2" />
      <base-tab-pane label="返回修改" name="3" />
    </base-tabs>
    <content-main :activeNames="activeNames" :count='count' :gysData='gysData' ></content-main>
  </div>
</template>
<script>
import BaseTabs from '@/components/common/tabs/base-tabs/base-tabs.vue'
import BaseTabPane from '@/components/common/tabs/base-tab-pane/base-tab-pane.vue'
import ContentMain from './essential-factor-examine-main.vue'
import { supplierApi } from '@/api/companyApi'
import Storage from '@/utils/storage'
export default {
  name: 'implementationReview',
  components: { BaseTabs, BaseTabPane, ContentMain },
  data () {
    return {
      count: 0,
      queryParas: {
        pageSize: 10,
        pageIndex: 1
      },
      activeNames: '1',
      gysData: []
    }
  },
  computed: {
  },
  methods: {
    hander () {
      Storage.setSession('activeNames', this.activeNames)
    },
    // 获取供应商数据
    gysList () {
      supplierApi.getList().then(res => {
        if (res.success) {
          this.gysData = res.data
        }
      })
    }
  },
  watch: {
    activeNames (val) {
      this.count++
    }
  },
  mounted () {
    if (Storage.getSession('activeNames')) {
      this.activeNames = Storage.getSession('activeNames')
    }
    this.gysList()
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-tabs--border-card{
  border: 0;
  box-shadow: none;
}
/deep/ .el-tabs__content{
  padding: 0 !important;
  padding-bottom: 16px !important;
}
.implementationReview {
  height: 100%;
  .tabs{
    margin-top:10px ;
  }
}
</style>
